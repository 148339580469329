import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
// import Services from 'sections/services';
// import ExcitingFeatures from 'sections/exciting-features';
// import WorkHard from 'sections/work-hard';
import UltimateFeatures from 'sections/ultimate-features';
// import HappyCustomer from 'sections/happy-customer';
// import Blog from 'sections/blog';
// import SubscribeUs from 'sections/subscribe-us';
import VideoTeaser from 'sections/video-teaser';

export default function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Weenove"
        description="Embed Dashboards in Any Application Or Website. Impress your customers by delivering top notch analytics right in your application."
      />
      <Banner />
      {/* <Services />
      <ExcitingFeatures />
      <WorkHard /> :*/}
      <VideoTeaser />
      <UltimateFeatures />
      {/* <HappyCustomer />
      <Blog />
      <SubscribeUs /> */}
    </Layout>
  );
}
