/** @jsx jsx */
import {
  jsx,
  Box,
  Container,
  Heading,
  Text,
  Label,
  Image as Img,
  Button,
} from 'theme-ui';
import { rgba } from 'polished';
import Input from 'components/input';

import axios from 'axios';

import React from 'react';
import { useForm } from "react-hook-form";

const onSubmit = (contact) => {
  axios({
    method: 'post',
    url: 'https://hook.integromat.com/7fz1qx2hg77nd4e5ms82t0itfrrzd6jk',
    data: contact
  });
};


const Banner = () => {
  const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm();

  return (
    <Box as="section" id="home" sx={styles.section}>
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.content}>
            <Heading as="h1">
              Embedded analytics for ISV and digital companies
            </Heading>
            <Text as="p">
              Embed dashboards in any application or website. Impress your customers by delivering top notch analytics right in your application.
            </Text>
            <Box sx={styles.subscribe}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Label htmlFor="email" variant="styles.srOnly">
                  Email
              </Label>
                {
                  !isSubmitted || errors.email
                    ? <Input
                      id="email"
                      type="email"
                      placeholder="Enter Email address"
                      {...register('email', { required: true })}
                      sx={styles.inputEmail}
                    />
                    : ""
                }   
                <Button variant="primary" type="submit">Request beta access</Button>
                {errors.email &&
                  <span>Email is required.</span>
                }
                {!errors.email && isSubmitted &&
                  <span>Thanks for your submission !</span>
                }
              </form>
            </Box>
          </Box>
          <Box as="div" sx={styles.illustration}>
            <img src="/images/dashboards-devices-600.png" alt="Dashboards" width="600px" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;

const styles = {
  inputEmail: {
    display: ['inline'],
    width: [300],
  },
  contentWrapper: {
    display: ['block', null, null, null, 'grid', 'flex'],
    gridTemplateColumns: ['1fr 1fr', null, null, null, '0.9fr 1.1fr'],
    gap: [0, 0, 0, 0, 40],
    alignItems: 'center',
    minHeight: [null, null, '100vh', '70vh', '50vh', '100vh'],
    pt: ['100px', null, null, '130px', '25px', null, 0],
    textAlign: ['center', null, 'left'],
  },
  content: {
    maxWidth: [null, null, null, '75%', '100%'],
    margin: [null, null, null, '0 auto', 0],
    textAlign: [null, null, null, 'center', 'left'],
    h1: {
      color: 'textSecondary',
      fontFamily: 'Crimson Text, Serif',
      fontWeight: 600,
      fontSize: ['34px', '34px', '34px', '52px', '40px', '49px', '62px'],
      lineHeight: [1.26, 1.26, 1.11],
    },
    p: {
      maxWidth: [450, null, null, 'none', 450],
      fontSize: ['14px', null, '18px', null, '16px', '15px', '18px'],
      lineHeight: [1.87, 1.87, 2.33, 2.33, 2],
      mt: ['25px', null, null, null, 4],
    },
  },
  subscribe: {
    display: 'flex',
    alignItems: 'center',
    mt: ['30px'],
    input: {
      mr: ['15px'],
      minHeight: ['45px', null, null, 60, 50, null, 60],
    },
    button: {
      minHeight: ['45px', null, null, 60, 50, null, 60],
      fontSize: ['14px', '14px', '16px'],
    },
  },
  sponsoredBy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', null, null, null, 'unset'],
    mt: ['30px', '30px', '40px', '40px', '30px'],
    span: {
      color: rgba('#566272', 0.6),
      fontSize: ['14px', '16px', '16px'],
    },
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    figure: {
      ml: ['10px', '16px', '28px', '16px', '16px'],
    },
    img: {
      maxWidth: ['69px', '85px', '100%', '100%', '79px', '100px', '100%'],
    },
  },
  illustration: {
    ml: [0, 0, '30px', 0, 0],
    mt: ['50px', null, null, null, 0],
    minWidth: ['auto', null, null, null, null, '600px'],
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    '.gatsby-image-wrapper': {
      maxWidth: [null, null, null, 566, 'none'],
      m: [null, null, null, '0 auto', null],
    },
    'img': {
      width: [300, 400, 400, 400, 400, 600]      
    },
  },
  
};
