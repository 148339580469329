/** @jsx jsx */
import {
  jsx,
  Box,
  Container,
  Heading,
  Text,
  Label,
  Image as Img,
  Button,
} from 'theme-ui';

import videoPoster from 'assets/images/biwee-pc-bleu-1200.png';
import biweeISVTrailer from 'assets/images/biwee-ISV-trailer.mp4';

import React from 'react'


const VideoTeaser = () => {
  return (
    <Box as="section" id="video" >
      <Container>      
        <video width="100%" controls poster={videoPoster}>
          <source src={biweeISVTrailer} type="video/mp4" />
        </video>
      </Container>
    </Box>
  );
};

export default VideoTeaser;

const styles = {
 
};
